<template>
  <ion-card color="white" class="practice-item">
    <ion-card-content>
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col class="name">
            {{ practice.name }}
          </ion-col>
          <!-- <ion-icon name="md-more" size="large" class="actions cursor-pointer" /> -->
          <ion-icon name="ios-arrow-forward" class="navigate" />
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { addIcons } from "ionicons";
import { arrowForward, more } from "ionicons/icons";

addIcons({
  "ios-arrow-forward": arrowForward.ios,
  "md-more": more.md
});

export default {
  name: "PracticeItem",
  props: {
    practice: Object
  }
};
</script>

<style scoped>
.practice-item {
  margin: 10px 0 !important;
  padding: 15px;
}

.practice-item:hover {
  background-color: var(--ion-color-white-shade);
}

ion-card-content {
  padding: 9px 13px;
}

ion-row {
  min-height: 32px;
}

.name {
  font-weight: bold;
  font-size: 16px;
}

ion-icon {
  color: var(--ion-color-medium);
}

.actions {
  padding: 0 15px;
}
.actions:hover {
  color: var(--ion-color-primary);
}

.navigate {
  font-size: 24px;
}
</style>
