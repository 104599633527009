<template>
  <PrimePage title="Practice Administration" subtitle="Practices" :loading="loading">
    <ion-grid class="margin-left-ten">
      <ion-row v-for="org in orgs" :key="org.id">
        <ion-col size="45">
          <router-link :to="{ name: 'general-settings', params: { id: org.id, orgName: org.name } }" exact>
            <PracticeItem :practice="org" />
          </router-link>
        </ion-col>
      </ion-row>
      <ion-row v-if="$can(I.ADD_ORGANIZATION)">
        <ion-col size="45">
          <router-link :to="{ name: 'general-settings', params: { id: 'new' } }" exact>
            <button type="button" class="prime-button button-secondary button-block">
              <ion-icon name="md-add" />
              Add Practice
            </button>
          </router-link>
        </ion-col>
      </ion-row>
    </ion-grid>
  </PrimePage>
</template>

<script>
import { addIcons } from "ionicons";
import { add } from "ionicons/icons";
import PrimePage from "@/components/Global/PrimePage";
import PracticeItem from "@/components/Settings/Practice/PracticeItem";
import { send as httpSend } from "@/services/Api";
import { debounce } from "lodash";

addIcons({
  "md-add": add.md
});

export default {
  name: "PracticeOverview",
  components: {
    PrimePage,
    PracticeItem
  },
  data() {
    return {
      loading: false,
      orgs: []
    };
  },
  created() {
    this.getOrganizations();
  },
  activated() {
    this.getOrganizations();
  },
  methods: {
    getOrganizations: debounce(
      function() {
        const method = "get";
        const path = document.config.myOrganizations;
        this.loading = true;

        httpSend({ path, method, authToken: true })
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            this.$ionic.toastController
              .create({
                header: "Failed to get your Practice list",
                message: error,
                duration: 5000,
                position: "top"
              })
              .then(m => m.present());
            khanSolo.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      1000,
      { leading: true, trailing: false }
    )
  }
};
</script>

<style scoped>
.prime-button.button-secondary.button-block {
  height: 80px;
  margin: 10px 0;
  font-size: 16px;
}

a {
  text-decoration: none;
}
</style>
